<script>
import * as echarts from "echarts";
import {axios} from "@/utils/request";
import Map from "@/components/map";

export default {
    name: "MapChart",
    data() {
        return {
            mapChart: null,
            defaultRegion: {name: "思茅区", adcode: 530802, regionName: "SiMao", level: 3},
            // 区域
            regions: [
                {name: "南屏镇", adcode: 530802102, regionName: "NanPing", level: 4},
                {name: "尧都区", adcode: 141002, regionName: "YaoDu", level: 3},
                {name: "曲沃县", adcode: 141021, regionName: "QuWo", level: 3},
                {name: "翼城县", adcode: 141022, regionName: "YiCheng", level: 3},
                {name: "襄汾县", adcode: 141023, regionName: "XiangFen", level: 3},
                {name: "洪洞县", adcode: 141024, regionName: "HongDong", level: 3},
                {name: "古县", adcode: 141025, regionName: "GuXian", level: 3},
                {name: "安泽县", adcode: 141026, regionName: "AnZe", level: 3},
                {name: "浮山县", adcode: 141027, regionName: "FuShan", level: 3},
                {name: "吉县", adcode: 141028, regionName: "JiXian", level: 3},
                {name: "乡宁县", adcode: 141029, regionName: "XiangNing", level: 3},
                {name: "大宁县", adcode: 141030, regionName: "DaNing", level: 3},
                {name: "隰县", adcode: 141031, regionName: "XiXian", level: 3},
                {name: "永和县", adcode: 141032, regionName: "YongHe", level: 3},
                {name: "蒲县", adcode: 141033, regionName: "PuXian", level: 3},
                {name: "汾西县", adcode: 141034, regionName: "FenXi", level: 3},
                {name: "侯马市", adcode: 141081, regionName: "HouMa", level: 3},
                {name: "霍州市", adcode: 141082, regionName: "HuoZhou", level: 3}
            ],
            clickArea: null,//点击区域名称
            districtCode: null
        };
    },
    components: {
        Map
    },
    methods: {
        //地图返回
        goBack() {
            let _this = this;
            this.clickArea = null;
            //清空刚刚的缓存
            window.localStorage.removeItem("community_id");
            window.localStorage.removeItem("grid_id");
            window.localStorage.removeItem("map_level");

            _this.$nextTick(() => {
                _this.renderMap();
            });
        },
        renderMap(region) {
            region = region || this.defaultRegion;
            const chartDom = document.getElementById("map-chart");
            // 判断地图是否渲染
            let myChart = echarts.getInstanceByDom(chartDom);
            // 如果渲染则清空地图
            if (myChart != null) {
                myChart.dispose();
            }
            // 初始化地图
            myChart = echarts.init(chartDom);
            myChart.showLoading({
                text: "数据加载中...", // 加载提示文本
                color: "#1f418d",      // 加载图标颜色
                textColor: "#1f418d",  // 加载文本颜色
                maskColor: "rgba(0, 0, 0, 0.6)" // 遮罩层颜色
            });

            let seriesData = [];
            axios.get("/api/api/datav/risk/stats", {
                params: {
                    districtCode: region.adcode
                }
            }).then((data) => {
                myChart.hideLoading();

                const rows = data.data;
                seriesData = rows.map((row) => {
                    return {
                        name: row.districtName,
                        value: row.checkCount,
                        districtCode: row.districtCode
                    };
                });

                // 更新数据
                myChart.setOption({
                    visualMap: {
                        min: 10,
                        max: region.level === 2 ? 1000 : 350
                    },
                    series: [{
                        data: seriesData
                    }]
                });
            });

            const option = {
                title: {},

                toolbox: {
                    show: false,
                    orient: "vertical",
                    left: "right",
                    top: "center",
                    feature: {
                        dataView: {readOnly: false},
                        restore: {},
                        saveAsImage: {}
                    }
                },
                // 开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
                roam: true,
                zoom: 1.2,
                scaleLimit: {
                    min: 1.1,
                    max: 1.5
                },
                visualMap: {
                    min: 10,
                    max: 5000,
                    text: ["High", "Low"],
                    realtime: true,
                    calculable: true,
                    inRange: {
                    }
                },
                series: [
                    {
                        name: "思茅街道",
                        type: "map",
                        map: region.name,
                        label: {
                            show: true,
                            formatter: "{b}\n{c}",
                            lineHeight: 24,
                            color: "#fff",
                            fontSize: 16
                        },
                        itemStyle: {
                            areaColor: "rgb(128, 128, 128)"
                        },
                        emphasis: {
                            itemStyle: {
                                areaColor: "rgb(213, 214, 79)"
                            }
                        },
                        data: seriesData
                    }
                ]
            };

            let geojson = require(`@/assets/data/geo/${region.adcode}.json`);

            // 注册地图
            echarts.registerMap(region.name, geojson);
            option && myChart.setOption(option);

            myChart.on("click", e => {
                this.districtCode = e.data.districtCode;
                //点击区域的名称
                // if (e.name == "思茅街道") {
                    this.clickArea = e.data.name;
                // }
                //相关数据加载
                const region = this.regions.find((item) => item.name === e.name);
                console.log('------------region--------', region);
                this.switchMap(region);
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        // 切换市
        switchMap(region) {
            if (region && region.level === 4) {
                return;
            }

            this.renderMap(region);
            this.$emit("select", region);
        },
        mapInit() {

        },
        getDataByQuery(id) {
            console.log("-----------接收传参---------", id);
        }
    },
    mounted() {
        console.log('--------------------', this.clickArea);
        //清空刚刚的缓存
        window.localStorage.removeItem("community_id");
        window.localStorage.removeItem("grid_id");
        window.localStorage.removeItem("map_level");

        let token = localStorage.getItem("access_token");
        if (!token) {
            this.$router.replace({path: "/login"});
            return false;
        }
        this.$nextTick(() => {
            this.renderMap();
        });
    }
};
</script>

<template>
    <div class="map-wrapper">
        <div v-if="!clickArea" id="map-chart" class="map-chart"></div>
        <Map :districtCode="districtCode" @getDataByQuery="getDataByQuery" @goBack="goBack" v-else/>
    </div>
</template>

<style scoped lang="scss">
.map-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .map-chart {
        width: 100%;
        height: 100%;
    }
}
</style>
