<template>
    <div class="leftBox">

        <div class="pingfen">
            <dv-border-box-13>
                <div style="height: 100%;">
                    <div class="pfTitle flex-between">
                        <div class="flex">
                            <img :src="aqpf" alt=""/>
                            <span class="titleText">行业/部门检查情况</span>
                        </div>
                        <div class="flex">
                            <select class="select" style="margin-right: 10px;" placeholder="请选择年">
                                <option>2024年</option>
                            </select>
                            <select class="" placeholder="请选择月">
                                <option v-for="item in range(1, 13)" :key="item" :selected="item === 6">{{
                                        item
                                    }}月
                                </option>
                            </select>
                        </div>
                    </div>
                    <div style="height: 100%;">
                        <div style="width:100%;height: 160px;" id="echart1"></div>
                    </div>
                </div>
            </dv-border-box-13>
        </div>


        <div class="paimin">
            <dv-border-box-13 style="height: 300px;">
                <div style="height: 100%">
                    <div class="pfTitle flex" style="margin-bottom: 30px;">
                        <img :src="jzaqpf" alt=""/>
                        <span class="titleText">检查完成度占比</span>
                    </div>
                    <div style="height: 70%;">
                        <div style="height:100%;width: 100%;" id="echart2"></div>
                    </div>
                </div>
            </dv-border-box-13>
        </div>

        <div class="paimin" v-if="false">
            <dv-border-box-13 style="height: 350px;">
                <div style="height:100%;">
                    <div class="pfTitle flex" style="margin-bottom: 20px;">
                        <img :src="aqyh" alt=""/>
                        <span class="titleText">隐患类型占比</span>
                    </div>
                    <div style="height:80%;">
                        <div style="height:100%;width: 100%;" id="echart3"></div>
                    </div>
                </div>
            </dv-border-box-13>
        </div>

        <div class="paimin">
            <dv-border-box-13 style="height: 350px;">
                <div style="height:100%;">
                    <div class="pfTitle flex" style="margin-bottom: 20px;">
                        <img :src="aqyh" alt=""/>
                        <span class="titleText">各检查类型场所占比</span>
                        <div style="color:#999;font-size:12px;margin-top:6px;">
                            【注:'不放心'为每月检查一次，‘稍微放心’为每季度检查一次，‘放心’为每半年检查一次】
                        </div>
                    </div>
                    <div style="height:80%;">
                        <div style="height:100%;width: 100%;" id="echart4"></div>
                    </div>
                </div>
            </dv-border-box-13>
        </div>
    </div>
</template>

<script>
import aqpf from "@/assets/img/aqpf.png";
import aqdj from "@/assets/img/aqdj.png";
import jzaqpf from "@/assets/img/jzaqpf.png";
import aqyh from "@/assets/img/aqyh.png";
import aqyhtop100 from "@/assets/img/aqyhtop100.png";
import xfjs from "@/assets/img/xfjs.png";
import ldbj from "@/assets/img/ldbj.png";
import jgbj from "@/assets/img/jgbj.png";
import rcxj from "@/assets/img/rcxj.png";
import whtj from "@/assets/img/whtj.png";
import gzt from "@/assets/img/gzt.png";
import hj from "@/assets/img/hj.png";
import zfjg from "@/assets/img/zfjg.png";
import zfjgtj from "@/assets/img/zfjgtj.png";
import {axios} from "@/utils/request";
import * as echarts from "echarts";
import {range} from "@/utils";
import {EventBus} from "@/utils/eventBus";

export default {
    name: "LeftWrapper",
    props: {
        region: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        region: {
            handler: function (newValue) {
                if (newValue) {
                    this.init();
                }
            }
        }
    },
    data() {
        return {
            aqpf,
            aqdj,
            jzaqpf,
            aqyh,
            aqyhtop100,
            xfjs,
            ldbj,
            jgbj,
            rcxj,
            whtj,
            gzt,
            hj, zfjg, zfjgtj,
            datalist: {},
            checkData: [],
            hyData: [],
            dangerData: [],
            shiParams: null,
            xian: null,
            tmp: false
        };
    },
    methods: {
        range,
        //市场部门及网格员检查情况
        renderBarChart() {
            const chartDom = document.getElementById("echart1");
            // 判断地图是否渲染
            let myChart = echarts.getInstanceByDom(chartDom);
            // 如果渲染则清空地图
            if (myChart != null) {
                myChart.dispose();
            }
            //市场部门及网格员检查情况
            let echart1 = echarts.init(chartDom);

            echart1.showLoading({
                text: "数据加载中...", // 加载提示文本
                color: "#1f418d",      // 加载图标颜色
                textColor: "#1f418d",  // 加载文本颜色
                maskColor: "rgba(0, 0, 0, 0.3)" // 遮罩层颜色
            });

            axios.get("/api/api/datav/risk/stats", {
                params: {
                    districtCode: this.region.adcode
                }
            }).then(({data}) => {
                echart1.hideLoading();

                const rows = data;
                const categoryNames = rows.filter((item) => item.checkCount > 0).map((row) => row.districtName);
                const values = rows.filter((item) => item.checkCount > 0).map((row) => row.checkCount);

                const option = {
                    color: ["#0256ff"],
                    xAxis: {
                        type: "category",
                        nameRotate: 60,
                        data: categoryNames
                    },
                    textStyle: {
                        color: "#fff"
                    },
                    grid: {
                        top: "10px",
                        bottom: "20px",
                        right: "10px",
                        left: "70px"
                    },
                    yAxis: {
                        type: "value"
                    },
                    tooltip: {
                        trigger: "axis", //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
                        axisPointer: {// 坐标轴指示器，坐标轴触发有效
                            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    series: [
                        {
                            barWidth: "15px",
                            data: values,
                            type: "bar"
                        }
                    ]
                };
                echart1.setOption(option);
                echart1.hideLoading();
            });
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        //检查完成情况
        renderCheckPieChart() {
            let _this = this;
            let community_id = window.localStorage.getItem("community_id") || "";
            let grid_id = window.localStorage.getItem("grid_id") || "";
            let mapLevel = window.localStorage.getItem("map_level") || "";
            const chartDom = document.getElementById("echart2");
            //行业情况占比
            let echart2 = echarts.init(chartDom);

            echart2.showLoading({
                text: "数据加载中...", // 加载提示文本
                color: "#1f418d",      // 加载图标颜色
                textColor: "#1f418d",  // 加载文本颜色
                maskColor: "rgba(0, 0, 0, 0.3)" // 遮罩层颜色
            });

            let seriesData = [];
            axios.get("/api/api/smapi/getPlaceCheckStatusData", {
                params: {
                    districtCode: this.region.adcode,
                    communityCode: "",
                    communityId: community_id,
                    gridId: grid_id,
                    mapLevel: mapLevel
                }
            }).then(({data}) => {
                echart2.hideLoading();
                seriesData = [{
                    name: "已完成检查数量",
                    value: data.hadChecked,
                    itemStyle: {color: "#91cc75"}
                }, {
                    name: "未完成检查数量",
                    value: data.noChecked,
                    itemStyle: {color: "#ee6567"}
                }];

                // 更新数据
                echart2.setOption({
                    series: [{
                        data: seriesData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 1,
                                shadowColor: "#ffcc00"
                            }
                        }
                    }]
                });

                // 为图表添加点击事件
                echart2.on("click", function (params) {
                    console.log(params);
                    // alert('你点击了' + params.name + '，数值为' + params.value);
                });
            });


            let option = {
                tooltip: {
                    trigger: "item"
                },
                textStyle: {
                    color: "#0eb0c9"
                },
                grid: {
                    top: "10px"
                },
                series: [
                    {
                        name: "检查家次",
                        type: "pie",
                        radius: "80%",
                        labelLayout: {
                            hideOverlap: true // 是否隐藏重叠标签
                        },
                        label: {
                            show: true,
                            formatter: "{b}\n{c}",
                            lineHeight: 24,
                            color: "#fff",
                            fontSize: 16
                        },
                        emphasis: {
                            itemStyle: {
                                color: "#fff"
                            }
                        },
                        data: seriesData
                    }
                ]
            };
            option && echart2.setOption(option);
        },
        //隐患类型占比
        renderTroublePieChart() {
            const checkGroups = ["火灾风险管控", "设施器材设置", "管理教育培训"];

            const eData = checkGroups.map((groupName) => {
                return {
                    name: groupName,
                    value: this.getRandomInt(100, 5000)
                };
            });

            const chartDom = document.getElementById("echart3");
            let echart3 = echarts.init(chartDom);
            echart3.showLoading({
                text: "数据加载中...", // 加载提示文本
                color: "#1f418d",      // 加载图标颜色
                textColor: "#1f418d",  // 加载文本颜色
                maskColor: "rgba(0, 0, 0, 0.3)" // 遮罩层颜色
            });

            let option = {
                tooltip: {
                    trigger: "item"
                },
                textStyle: {
                    color: "#0eb0c9"
                },
                grid: {
                    top: "10px"
                },
                series: [
                    {
                        name: "隐患数",
                        type: "pie",
                        radius: "60%",
                        data: eData,
                        emphasis: {
                            itemStyle: {
                                color: "#fff"
                            }
                        }
                    }
                ]
            };
            option && echart3.setOption(option);

            echart3.hideLoading();
        },
        //检查类型占比
        getCheckLevelData() {
            let _this = this;
            let community_id = window.localStorage.getItem("community_id") || "";
            let grid_id = window.localStorage.getItem("grid_id") || "";
            let mapLevel = window.localStorage.getItem("map_level") || "";
            const chartDom = document.getElementById("echart4");
            let echart4 = echarts.init(chartDom);
            echart4.showLoading({
                text: "数据加载中...", // 加载提示文本
                color: "#1f418d",      // 加载图标颜色
                textColor: "#1f418d",  // 加载文本颜色
                maskColor: "rgba(0, 0, 0, 0.3)" // 遮罩层颜色
            });
            axios.get("/api/api/smapi/getCheckLevelData", {
                params: {
                    commounityCode: "",
                    communityId: community_id,
                    gridId: grid_id,
                    mapLevel: mapLevel
                }
            }).then(({data}) => {
                let eData;
                if (!_this.tmp) {
                    eData = [
                        {
                            value: data.bfxCount,
                            name: "不放心",
                            itemStyle: {color: "#ee6567"}
                        },
                        {
                            value: data.fxCount,
                            name: "放心",
                            itemStyle: {color: "#91cc75"}
                        },
                        {
                            value: data.swfxCount,
                            name: "稍微放心",
                            itemStyle: {color: "#e6a23c"}
                        }
                    ];
                    let option = {
                        tooltip: {
                            trigger: "item"
                        },
                        textStyle: {
                            color: "#0eb0c9"
                        },
                        grid: {
                            top: "10px"
                        },
                        series: [
                            {
                                name: "检查类型占比",
                                type: "pie",
                                radius: "60%",
                                data: eData,
                                labelLayout: {
                                    hideOverlap: true // 是否隐藏重叠标签
                                },
                                label: {
                                    show: true,
                                    formatter: "{b}\n{c}",
                                    lineHeight: 24,
                                    color: "#fff",
                                    fontSize: 16
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: "#fff"
                                    }
                                }
                            }
                        ]
                    };
                    option && echart4.setOption(option);
                    echart4.hideLoading();
                } else {
                    eData = [
                        {
                            value: data.bfxCount,
                            name: "不放心",
                            itemStyle: {color: "#ee6567"}
                        },
                        {
                            value: data.fxCount,
                            name: "放心",
                            itemStyle: {color: "#91cc75"}
                        },
                        {
                            value: data.swfxCount,
                            name: "稍微放心",
                            itemStyle: {color: "#e6a23c"}
                        }
                    ];
                    let option = {
                        tooltip: {
                            trigger: "item"
                        },
                        textStyle: {
                            color: "#0eb0c9"
                        },
                        grid: {
                            top: "10px"
                        },
                        series: [
                            {
                                name: "检查类型占比",
                                type: "pie",
                                radius: "60%",
                                data: eData,
                                labelLayout: {
                                    hideOverlap: true // 是否隐藏重叠标签
                                },
                                label: {
                                    show: true,
                                    formatter: "{b}\n{c}",
                                    lineHeight: 24,
                                    color: "#fff",
                                    fontSize: 16
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: "#fff"
                                    }
                                }
                            }
                        ]
                    };
                    option && echart4.setOption(option);
                    echart4.hideLoading();
                }

            });
        },
        init() {
            this.renderBarChart();
            this.renderCheckPieChart();
            // this.renderTroublePieChart();
            this.getCheckLevelData();
        },
        //监听器方法
        handleStorageChange(event) {
            this.renderCheckPieChart();
            this.getCheckLevelData();
        }
    },
    created() {
        //总线监听
        EventBus.$on("community_id_changed", this.handleStorageChange);
        this.$nextTick(() => {
            this.init();
        });
    },
    beforeDestroy() {
        // 移除监听
        EventBus.$off("community_id_changed", this.handleStorageChange);
    }
};
</script>

<style lang="scss" scoped>
.titleText {
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
}

select {
    padding: 8px 10px;
    width: 100px;
    border: none;
    border-radius: 6px;
    background: #F7F8FA;
    text-align: center;
    opacity: 0.8;
}

select:focus-visible {
    outline: none;
}

select::-ms-expand {
    display: none;
}

select option {
    border: none;
    color: #777777 !important;
    font-family: Arial;
}

select::selection {
    background: #B7E1FF;
}


.paimin {
    height: auto !important;
}
</style>
